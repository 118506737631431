@import "../colors";

//.shortCardContainer {
//  border: 1px solid red;
//}

.card-bottom-navigation {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
  font-size: 1.2em;
  background-color: transparent;
}

#unauthDialog {
  font-size: 1rem;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;

  .dialog-title {
    position: relative;
  }

  .dialog-content {
    max-width: 220px;
    min-width: 220px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
  }

  .unauthDialog-first {}

  .unauthDialog-second {
    margin-top: 35px;
    margin-bottom: 35px;
    font-weight: bold;
  }

  .unauthDialog-third {
    color: red;
    margin-bottom: 35px;
  }

  .secondButton {
    color: black;
    font-weight: bold;
  }
}

@mixin border-block($border-size: 1, $color: #777777) {
  .shortCardContainer {
    //margin: 1px 1px 1px 1px;
    //margin: 0px -1*$border-size 0px 0px;
    margin: 0*$border-size 0*$border-size 0*$border-size 10*$border-size;
  }

}

@mixin border-color($border-size: 1, $color: #777777) {
  border: $border-size solid $color;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
  //}

}

.MuiPaper-root {
  transition: none !important;
}

.desktop-shortCard-container-smallCard {
  top: 0px;
  position: relative;

  &.desktop-shortCard-container-smallCard-right {
    right: 0px;
  }

  &.desktop-shortCard-container-smallCard-left {
    left: 0px;
  }

  &:hover {
    .desktop-shortCard-container-inner {}
  }

  &.desktop-shortCard-container-bigCard-new {}
}

.hoverBall {
  .detail-detailTable-container {
    display: none;
  }
}

.productList-products-container {
  >div {
    margin-right: 2px;
  }
}

.productList-products-container-full {
  margin-right: 1px;
}

.desktopLayout .hoverBall:hover {
  .detail-detailTable-container {
    display: block;
    //position: absolute;
    z-index: 10000;
  }

  .desktop-shortCard-container-inner {
    background-color: white;
  }

  .desktop-shortCard-container-smallCard {
    width: 120%;

    &.desktop-shortCard-container-smallCard-right {
      position: absolute;
      //right: 22%;
      margin-right: -1px;

      .desktop-shortCard-container-bigCard-imageBlock-thumbs {
        top: 0px;
        left: 3px;
        //right: 0px;
        width: 16.6666667%;
      }
    }

    &.desktop-shortCard-container-smallCard-left {
      position: absolute;

      //right: 22%;
      //margin-left: 2px;
      .shortCardContainer {
        //width: 75%;
        position: relative;
        top: 0px;
        right: 0px;
        margin-right: 1px;
        left: -16.6666667%;
        //width: 83.333333333%;
      }

      .desktop-shortCard-container-bigCard-imageBlock-thumbs {
        position: relative;
        top: 0px;
        margin-right: -1px;
        //margin-left: 1px;
        left: 82.333333333%;
        width: 16.6666667%;
      }
    }

    .desktop-shortCard-container-inner {
      position: relative;
      display: block;

      //justify-content: space-between;
      .shortCardContainer {
        //width: 100%;
      }
    }
  }

  .desktop-shortCard-container-bigCard-imageBlock-thumbs {
    display: block;
    position: relative;
    width: 20%;
    overflow-y: auto;
    max-height: 45vh;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: gray
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
      background-color: #F5F5F5;
    }
  }

  .desktop-shortCard-container-bigCard-regular {
    $color: $color-item-regular;
    $border-size: 1;
    @include border-block($border-size, $color);
    @include border-color($border-size, $color);
  }

  .desktop-shortCard-container-bigCard-new {
    $color: $color-new-items;
    $border-size: 1px;
    @include border-block($border-size, $color);
    @include border-color($border-size, $color);
  }

  .desktop-shortCard-container-bigCard-sale {
    $color: $color-sale;
    $border-size: 1px;
    @include border-block($border-size, $color);
    @include border-color($border-size, $color);
  }

  .desktop-shortCard-container-bigCard-zakaz {
    $color: $color-preorder;
    $border-size: 5px;
    @include border-block($border-size, $color);
    @include border-color($border-size, $color);
  }

}

.desktop-shortCard-container-bigCard-imageBlock-thumbs {

  //margin-left: 3px;
  //margin-right: 3px;
  img {
    max-width: 100%;
    //max-width: 70px;
    width: 100%;
    //width: 70px;
    //padding-left: 3px;
    //padding-right: 3px;
    //max-height: 70px;
    //height: 70px;
    min-height: 70px;
    object-fit: contain;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

@mixin right-left($border-size: 1, $color: #77777777) {
  //  &.desktop-shortCard-container-smallCard-right {
  //    border: $border-size solid $color !important;
  //    //border-top: $border-size solid $color !important;
  //    //border-right: $border-size solid $color !important;
  //    .desktop-shortCard-container-bigCard-imageBlock-thumbs {
  //      //left: -70-(2*$border-size);
  //    }
  //    .desktop-shortCard-container-bigCard-detailTable {
  //      //left: -70-(2*$border-size);
  //    }
  //    .desktop-shortCard-container-bigCard-imageBlock-thumbs {
  //      border-left: $border-size solid $color !important;
  //      border-top: $border-size solid $color !important;
  //    }
  //
  //    .desktop-shortCard-container-bigCard-detailTable {
  //      //width: calc(100%+75px);
  //      border-left: $border-size solid $color !important;
  //      border-right: $border-size solid $color !important;
  //      border-bottom: $border-size solid $color !important;
  //    }
  //
  //    .desktop-shortCard-container-cardRoot {
  //      margin-top: 0*$border-size;
  //      margin-left: 0px;
  //      margin-right: $border-size;
  //      margin-bottom: -2*$border-size;
  //      padding-top: 0*$border-size;
  //      padding-left: 0px;
  //      padding-right: 0px;
  //      padding-bottom: 0*$border-size;
  //    }
  //    .shortCart-topBlock {
  //      margin-top: -1*$border-size;
  //      margin-right: 0px;
  //
  //    }
  //  }
  //  &.desktop-shortCard-container-smallCard-left {
  //    border: $border-size solid $color !important;
  //    //border-top: $border-size solid $color !important;
  //    //border-left: $border-size solid $color !important;
  //    .desktop-shortCard-container-bigCard-imageBlock-thumbs {
  //      //right: -70-(2*$border-size);
  //    }
  //    .desktop-shortCard-container-bigCard-detailTable {
  //      //right: -70-(2*$border-size);
  //    }
  //    .desktop-shortCard-container-bigCard-imageBlock-thumbs {
  //      border-right: $border-size solid $color !important;
  //      border-top: $border-size solid $color !important;
  //    }
  //
  //    .desktop-shortCard-container-bigCard-detailTable {
  //      //width: calc(100%+75px);
  //      //border-left: $border-size solid $color !important;
  //      //border-right: $border-size solid $color !important;
  //      //border-bottom: $border-size solid $color !important;
  //    }
  //    .desktop-shortCard-container-cardRoot {
  //      margin-top: 0*$border-size;
  //      margin-left: -1*$border-size;
  //      margin-right: 0px;
  //      margin-bottom: -2*$border-size;
  //      padding-top: 0*$border-size;
  //      padding-left: 0px;
  //      padding-right: 0px;
  //      padding-bottom: 0*$border-size;
  //    }
  //    .shortCart-topBlock {
  //      margin-top: -1*$border-size;
  //      margin-left: -1*$border-size;
  //      padding-left: 0px;
  //      margin-right: $border-size;
  //      //border: 1px solid black;
  //
  //    }
  //  }
}


.mobileLayout {
  .shortCard-mobile-container {
    margin-top: 90px;
  }
}


.productList-products-container-bottom {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  font-weight: bold;
  font-style: italic;
  color: gray;
  flex-direction: column;
}

.views-productList-topArea {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-left: 15px;
  height: 32px;

  .breadcrumbs {
    font-size: 1rem;
    color: gray;
    margin: auto 0px;
  }
}

.desktop-shortCard-container .noItems {
  margin-top: 120px;
  margin-left: 300px;
}

.desktop-shortCard-container-ad {
  @extend .desktop-shortCard-container
}

.desktopLayout {
  .desktop-shortCard-container.hoverBall {
    margin-top: 1px;
    margin-bottom: 1px;

    &:hover {
      margin-top: 0px;
      margin-bottom: 0px;
      z-index: 1010;
      background-color: white;
      box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);

      .shortCard-LikesBlock-container {
        z-index: 1200;
      }

      .desktop-shortCard-container-cardRoot {
        border-color: white;
        border: none;
        box-shadow: none;
      }

      .shortCard-badge.postavka {
        display: none;
      }

      .detail-detailTable-container {
        //position: absolute;
        //color:white;
        //display: inline-block;
        //top: 0px;
        //left: -21%;
        //right: 21%;
        //padding-left: -20%;
        //margin-right: 20%;
        //right: 0px;
        //width: 121%;
        //background-color: white;
      }

      .desktop-shortCard-container-bigCard-detailTable {
        //width: 100%;
        //margin: 0px 0px;
        //display: block;
        //position: absolute;
        //background-color: white;
        //box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
        //display: flex;
        //justify-content: stretch;
        //height: auto;
        //z-index: 1011;
      }


    }
  }

}


.desktop-shortCard-container-smallCard {
  .desktop-shortCard-container-bigCard-imageBlock-thumbs {
    display: none;
  }
}


.desktopLayout {

  /******************* Card ***********************/
  @media (min-width: 1441px) {
    .desktop-shortCard-container {
      width: 20%;
      height: 50%;
    }
  }

  @media (min-width: 1921px) {
    .desktop-shortCard-container {
      width: 16%;
      height: 50%;
    }
  }

  @media (max-width: 1440px) {
    .desktop-shortCard-container {
      width: 25%;
      height: 50%;
    }
  }

  @media (max-width: 1280px) {
    .desktop-shortCard-container {
      width: 33%;
    }
  }

  @media (max-width: 1024px) {
    .desktop-shortCard-container {
      width: 50%;
    }
  }

  @media (max-width: 800px) {
    .desktop-shortCard-container {
      width: 50%;
    }

    .productList-filter-container {
      display: none;
    }

    .productList-products-container {
      width: 100%;
      left: 0px;
    }
  }

  @media (max-width: 500px) {
    .desktop-shortCard-container {
      width: 100%;
    }
  }
}

/* End ************* Card ***********************/

.disable-item-button {
  display: none;
}

.desktop-shortCard-container.hoverBall:hover {
  .disable-item-button {
    position: absolute;
    top: -12px;
    right: -12px;
    width: 32px;
    height: 32px;
    z-index: 110;
    //background-color: transparent;
    border: 1px solid lightgrey;
    padding: 0 0;
    font-size: 24px;
    color: red;
    display: block;
    background-color: white;
    border-radius: 16px;
    box-shadow: -1px 1px 2px rgba(0, 0, 0, .5);
  }

  .disable-item-button:hover {
    box-shadow: -2px 2px 4px rgba(0, 0, 0, .5);

  }

}

.managerInStore {
  //position: absolute;
  //top: 50px;
  font-size: 1.5rem;
  color: white;
  box-shadow: 4px 4px 2px rgba(0, 0, 0, .7);
  border: 1px solid gray;
  padding: 3px 3px;
  background-color: rgba(0, 200, 0, .3);
  min-width: 45px;
  text-align: center;
  text-shadow: 2px 2px 2px black;
  //, -4px -4px 4px rgba(0,0,0,.7), 4px -4px 4px rgba(0,0,0,.7), -4px 4px 4px rgba(0,0,0,.7);
  font-weight: bold;
  margin-top: 2px;

  &.fullStore {
    background-color: lightcoral;
  }
}

$reservedModeBackgroundColor: #ff7f7f;

.reserveMode-on {
  background-color: $reservedModeBackgroundColor;

  .productList-products-container {
    background-color: $reservedModeBackgroundColor;
  }

  .embed-cart {
    background-color: $reservedModeBackgroundColor;
  }

  .mobileLayout .views-cartPage-body {
    background-color: $reservedModeBackgroundColor;
  }

  .shortCard-mobile-container {
    background-color: $reservedModeBackgroundColor;
  }

  .views-cartPage-body {
    min-height: 400px;
    min-height: 80vh;
  }
}

.shortCard-price-row {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  .row-card {
    background-color: white;
    padding: 5px 5px 5px 5px;
    height: 100%;
    box-shadow: 1px 1px 2px grey;
  }

  .rrp {
    color: red;
    font-size: 1.2rem;
  }

  .rrp-percent {
    color: green;
    font-size: 1.2rem;
    margin-right: 3px;
  }

  &.rrp-alert {
    background-color: rgba(255, 0, 0, .3);
  }
}

.shortCart-topBlock span:nth-of-type(1) {}

.artkulBlock-container {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  z-index: 10;
  //max-width: 70%;
  height: 1rem;
  font-size: .9rem;
  overflow: visible;
  width: 100%;
  position: relative;
}

button {
  &.action-button.cart-actions-pannel-select-variant-button.orangeText {
    color: black !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, .2);
  }

  &.cart-actions-pannel-select-variant-button:disabled {
    background-color: lightgrey !important;
  }
}

.linkToDetails {
  padding: .5rem;
  border-bottom: 1px solid lightgray;
  border-top: 1px solid lightgray;
  display: flex;
  justify-content: flex-end;

  a {
    color: black;
    text-decoration: none;
  }

  &:hover {
    text-shadow: 2px 2px 4px gray;
    font-weight: bold;
  }
}
